@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/Avenir-Light.eot");
  src: local("Avenir Light"), local("./assets/fonts/avenir/Avenir-Light"),
    url("./assets/fonts/avenir/Avenir-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/Avenir-Light.woff2") format("woff2"),
    url("./assets/fonts/avenir/Avenir-Light.woff") format("woff"),
    url("./assets/fonts/avenir/Avenir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/Avenir-Heavy.eot");
  src: local("Avenir Heavy"), local("./assets/fonts/avenir/Avenir-Heavy"),
    url("./assets/fonts/avenir/Avenir-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/Avenir-Heavy.woff2") format("woff2"),
    url("./assets/fonts/avenir/Avenir-Heavy.woff") format("woff"),
    url("./assets/fonts/avenir/Avenir-Heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/Avenir-Book.eot");
  src: local("Avenir Book"), local("./assets/fonts/avenir/Avenir-Book"),
    url("./assets/fonts/avenir/Avenir-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/Avenir-Book.woff2") format("woff2"),
    url("./assets/fonts/avenir/Avenir-Book.woff") format("woff"),
    url("./assets/fonts/avenir/Avenir-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/Avenir-Black.eot");
  src: local("Avenir Black"), local("./assets/fonts/avenir/Avenir-Black"),
    url("./assets/fonts/avenir/Avenir-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/Avenir-Black.woff2") format("woff2"),
    url("./assets/fonts/avenir/Avenir-Black.woff") format("woff"),
    url("./assets/fonts/avenir/Avenir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/Avenir-Medium.eot");
  src: local("Avenir Medium"), local("./assets/fonts/avenir/Avenir-Medium"),
    url("./assets/fonts/avenir/Avenir-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/Avenir-Medium.woff2") format("woff2"),
    url("./assets/fonts/avenir/Avenir-Medium.woff") format("woff"),
    url("./assets/fonts/avenir/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root {
  color-scheme: only light;

  --color-white: #ffffff;
  --color-bg: #ffffff;
  --color-text: #121619;
  --color-text-light: #555061;
  --color-accent: #4c31d8;
  --color-accent-light: #a194e4;
  --color-grey: #e4e4e4;
  --color-dark-grey: #797979;
  --color-warning: #ef3400;
  --transition-duration: 0.4s;
  --transition-duration-mid: 0.25s;
  --transition-duration-short: 0.1s;
  --margin-large: 0.6rem;
  --margin-middle: 0.4rem;
  --margin-small: 0.2rem;

  --wrap: wrap;
  --wrap-reverse: wrap-reverse;
  --wrap-reverse2: wrap-reverse;
  --font-size-normal: 1rem;
  --font-size-subsubheading: 1.4rem;
  --font-size-subheading: 1.6rem;
  --font-size-heading: 2.2rem;
  --font-size-title: 2.6rem;

  --margin-flexible: 8px;
  --margin-flexible-small: 4px;

  --height-column-procurement: 12%;
  --height-column-hr: 7%;
  --height-column-production: 25%;
  --height-column-sales: 8%;
  --height-column-profit: calc(
    100% -
      (
        var(--height-column-procurement) + var(--height-column-hr) +
          var(--height-column-production) + var(--height-column-sales)
      )
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "Avenir", sans-serif;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

br {
  display: block;
  margin-top: 16px;
  line-height: 1.4rem;
  content: " ";
}

.app {
  width: 100vw;
  min-width: 320px;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background-color: hsla(249, 59%, 73%, 1);
  background-image: radial-gradient(at 0% 0%, #e0e0ff 0px, transparent 50%),
    radial-gradient(at 100% 0%, #9e92e3 0px, transparent 50%),
    radial-gradient(at 0% 100%, #e3e3e3 0px, transparent 50%),
    radial-gradient(at 97% 96%, #472dd7 0px, transparent 50%);

  /*@media (min-width: 980px) {
    position: fixed;
    top: 0;
    height: 100svh;
  }*/
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.bg-util {
  width: 100%;
  height: 100vh;
}

/* HEADER STYLES */
/*#region*/

.app-header-container {
  position: fixed;
  top: 0px;
  backdrop-filter: blur(5px);
  mask: linear-gradient(to top, transparent, black 16px);
  padding: 8px 2vw 16px;
  width: 100%;
  min-width: 320px;
  z-index: 1000;
}

.app-header {
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  border: 1px solid var(--color-grey);
  max-width: 980px;
  padding: 24px;
  margin: 0 auto;
  background-color: var(--color-white);
  font-size: var(--font-size-normal);
  transition: box-shadow var(--transition-duration);
}

.app-header:hover {
  box-shadow: rgba(100, 100, 111, 0.15) 0px 6px 18px 0px;
}

.app-header-menu {
  position: inherit;
  visibility: visible;
  cursor: pointer;
}

.app-header-name {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  margin: auto 0;
}

.app-header-name-bold {
  font-weight: 600;
}

.app-header-name-slogan {
  position: absolute;
  visibility: hidden;
}

.app-header-space {
  flex-grow: 1;
}

.app-header-sections {
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: absolute;
  visibility: hidden;
}

.app-header-section:hover {
  cursor: pointer;
}

.app-header-button {
  margin: -12px -12px -12px 16px;
  position: absolute;
  visibility: hidden;
}

/*#endregion*/

/* WELCOME BANNER STYLES */
/*#region*/

.welcome-banner-container {
  /*height: calc(100vh + 32px);*/
  height: 100svh;
  margin-bottom: -32px;
  min-height: 632px;
  padding: 96px 2vw 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: hsla(249, 59%, 73%, 1);
  background-image: radial-gradient(
      at 0% 0%,
      hsla(240, 100%, 94%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 100% 0%, hsla(249, 59%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 0%, 89%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(249, 68%, 51%, 1) 0px, transparent 50%);
}

.welcome-banner {
  height: fit-content;
  padding: 0 24px;
  margin: auto auto;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  scroll-margin-top: 1028px;
}

.welcome-banner-headline {
  max-width: 650px;
  font-size: var(--font-size-title);
  font-weight: 600;
}

.welcome-banner-text {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  visibility: hidden;
  font-size: 1rem;
}

.welcome-banner-text.mobile {
  visibility: visible;
  position: inherit;
}

@keyframes buttonRotate {
  0% {
    background: conic-gradient(
      from 90deg,
      var(--color-white),
      var(--color-accent-light),
      var(--color-white)
    );
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(360deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    background: transparent;
    transform: rotate(360deg);
  }
}

.welcome-banner-button {
  position: relative;
  border-radius: 14px;
  content: "";
  width: fit-content;
  margin: -2px;
  padding: 2px;
  overflow: hidden;
}

.welcome-banner-button::before {
  content: "";
  position: absolute;
  top: -200%;
  left: -10%;
  width: 120%;
  height: 500%;
  background-position: center center;
  background: transparent;
  animation: buttonRotate 12s linear 4s;
}

.welcome-banner-button .button {
  position: relative;
  padding: 16px 64px;
  width: 100%;
  max-width: 250px;
}

.welcome-banner-button .button-font {
  font-size: var(--font-size-normal);
  margin-left: auto;
  margin-right: auto;
}

.welcome-banner-arrow {
  font-size: var(--font-size-heading);
  font-weight: 200;
  margin: 0 auto 64px;
  cursor: pointer;
}

/*#endregion*/

/* ABOUT ME STYLES */
/*#region*/

.about-me-container {
  width: 100%;
  min-width: 320px;
  padding: 32px 2vw 64px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-white);
  border-radius: 32px 32px 0 0;
  background-color: var(--color-white);
}

.about-me {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: var(--margin-flexible);
}

.about-me-headline {
  max-width: 650px;
  font-size: var(--font-size-heading);
  font-weight: 600;
  scroll-margin-top: 128px;
}

.about-me-content {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: var(--wrap-reverse2);
}

.about-me-content-card {
  position: relative;
  height: 93px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 16px;
  padding: 16px 16px 16px 106px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 6px 18px 0px;
  z-index: 3;
}

.about-me-content-pic {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.about-me-content-pic::before {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 20px;
  height: 100%;
  content: "";
  z-index: 2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--color-white) 90%
  );
}

.about-me-content-pic img {
  vertical-align: top;
  border-radius: 15px 0 0 15px;
  width: 91px;
  height: 91px;
  max-height: 120px;
  margin: 0;
  z-index: 1;
}

.about-me-socials {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 4;
}

.about-me-social-text {
  font-size: 0.9rem;
  margin: auto 0;
  word-break: break-all;
}

.about-me-content-text {
  /*max-width: 800px;*/
  margin: auto 0;
  /*display: flex;
  flex-direction: column;
  gap: 8px;*/
}

.about-me-content-text-paragraph {
  margin-top: 8px;
  font-size: 1rem;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.about-me-logos {
  position: relative;
  flex-wrap: nowrap;
  gap: 0px;
  overflow: hidden;
  padding: var(--margin-flexible) 0;
}

.about-me-logos::before,
.about-me-logos::after {
  position: absolute;
  top: 0;
  width: min(150px, 16vw);
  height: 100%;
  content: "";
  z-index: 2;
}

.about-me-logos::before {
  left: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    var(--color-white)
  );
}

.about-me-logos::after {
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--color-white)
  );
}

.about-me-logos-slide {
  display: flex;
  flex-direction: row;
  width: 470.4vw;
  max-width: 2831.4px;
  animation: slide 25s linear infinite;
  animation-fill-mode: forwards;
}

.about-me-logos-slide img {
  max-height: min(48px, 8vw);
  flex-grow: 1;
  object-fit: scale-down;
  margin: auto min(36px, 6vw);
}

/*#endregion*/

/* APPROACH STYLES */
/*#region*/

.approach-container {
  margin-top: -32px;
  padding: 32px 2vw 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-accent);
  border-radius: 32px 32px 0 0;
  background-color: var(--color-accent);
}

.approach {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: var(--margin-flexible);
}

.approach-headline {
  max-width: 650px;
  font-size: var(--font-size-heading);
  font-weight: 600;
  scroll-margin-top: 128px;
  color: var(--color-white);
}

@keyframes growProcurement {
  0% {
    height: 8%;
  }
  10% {
    height: 8%;
  }
  11% {
    height: 15%;
  }
  21% {
    height: 15%;
  }
  22% {
    height: 5%;
  }
  99% {
    height: 5%;
  }
  100% {
    height: 8%;
  }
}

@keyframes growHr {
  0% {
    height: 7%;
  }
  54% {
    height: 7%;
  }
  55% {
    height: 20%;
  }
  65% {
    height: 20%;
  }
  66% {
    height: 5%;
  }
  99% {
    height: 5%;
  }
  100% {
    height: 7%;
  }
}

@keyframes growProduction {
  0% {
    height: 30%;
  }
  32% {
    height: 30%;
  }
  33% {
    height: 36%;
  }
  43% {
    height: 36%;
  }
  44% {
    height: 28%;
  }
  99% {
    height: 28%;
  }
  100% {
    height: 30%;
  }
}

@keyframes growSales {
  0% {
    height: 10%;
  }
  76% {
    height: 10%;
  }
  77% {
    height: 21%;
  }
  87% {
    height: 21%;
  }
  88% {
    height: 8%;
  }
  99% {
    height: 8%;
  }
  100% {
    height: 10%;
  }
}

@keyframes growProfit {
  0% {
    height: 45%;
    top: 17%;
  }
  10% {
    height: 45%;
    top: 17%;
  }
  11% {
    height: 38%;
    top: 24%;
  }
  21% {
    height: 38%;
    top: 24%;
  }
  22% {
    height: 48%;
    top: 14%;
  }
  32% {
    height: 48%;
    top: 14%;
  }
  33% {
    height: 42%;
    top: 20%;
  }
  43% {
    height: 42%;
    top: 20%;
  }
  44% {
    height: 50%;
    top: 12%;
  }
  54% {
    height: 50%;
    top: 12%;
  }
  55% {
    height: 36%;
    top: 26%;
  }
  65% {
    height: 36%;
    top: 26%;
  }
  66% {
    height: 52%;
    top: 10%;
  }
  76% {
    height: 52%;
    top: 10%;
  }
  77% {
    height: 41%;
    top: 21%;
  }
  87% {
    height: 41%;
    top: 21%;
  }
  88% {
    height: 55%;
    top: 7%;
  }
  98% {
    height: 55%;
    top: 7%;
  }
  100% {
    height: 45%;
    top: 17%;
  }
}

.approach-graphic {
  margin: 16px auto;
  position: relative;
  width: 100%;
  max-width: 560px;
  height: 100%;
  min-height: 400px;
  /*border-radius: 16px;
  border: 1px solid var(--color-white);*/
  padding: 0 0 16px;
}

.approach-graph-container {
  margin: -4px auto 4px;
  position: relative;
  width: 100%;
  max-width: 520px;
  min-height: 200px;
  aspect-ratio: 3/2;
  border-radius: 16px;
  flex-grow: 1;
}

.approach-graph-axis {
  position: absolute;
  top: 62%;
  left: 2.5%;
  height: 1px;
  width: 95%;
  background-color: var(--color-white);
}

.approach-graph-description {
  position: absolute;
  top: 0%;
  left: 3.75%;
  width: 74%;
  height: 62.5%;
  display: flex;
  flex-direction: column;
}

.approach-graph-description-padding {
  flex-grow: 1;
}

.approach-graph-description-text {
  text-align: center;
  padding-bottom: var(--margin-flexible-small);
  margin: 0 var(--margin-flexible-small) var(--margin-flexible-small);
  border-bottom: 0.5px solid var(--color-grey);
  font-size: 14.4px;
  -webkit-text-size-adjust: 100%;
}

.approach-graph-labels {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.approach-graph-label {
  position: relative;
  width: 18.5%;
  flex-grow: 1;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.approach-graph-label-text {
  position: relative;
  text-align: center;
}

.approach-graph-columns {
  position: absolute;
  top: 0%;
  left: 5%;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2.6%;
}

.approach-graph-column {
  position: relative;
  top: 62%;
  flex-grow: 1;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--color-white);
  width: 15.92%;
  padding-top: 8px;
  text-align: center;
  font-size: 12.8px;
  -webkit-text-size-adjust: 100%;
}

.approach-graph-column.procurement {
  height: var(--height-column-procurement);
  animation: growProcurement 20s linear infinite;
}
.approach-graph-column.hr {
  height: var(--height-column-hr);
  animation: growHr 20s linear infinite;
}
.approach-graph-column.production {
  height: var(--height-column-production);
  animation: growProduction 20s linear infinite;
}
.approach-graph-column.sales {
  height: var(--height-column-sales);
  animation: growSales 20s linear infinite;
}

.approach-graph-column.profit {
  border-radius: 8px 8px 0 0;
  top: calc(62% - var(--height-column-profit));
  height: var(--height-column-profit);
  background-color: var(--color-white);
  color: var(--color-text);
  font-size: 14.4px;
  -webkit-text-size-adjust: 100%;
  font-weight: 600;
  animation: growProfit 20s linear infinite;
}

.approach-content-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 680px;
}

.approach-content-text.right {
  margin-left: auto;
}

.approach-content-left {
  width: 100%;
  max-width: 980px;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.approach-content-subheading {
  font-size: var(--font-size-subheading);
  font-weight: 600;
}

.approach-content-text-paragraph {
  font-size: 1rem;
}

.approach-content-right {
  max-width: 980px;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: end;
}

@keyframes slideChallenges {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(0%);
  }
  11% {
    transform: translateY(-20%);
  }
  32% {
    transform: translateY(-20%);
  }
  33% {
    transform: translateY(-40%);
  }
  54% {
    transform: translateY(-40%);
  }
  55% {
    transform: translateY(-60%);
  }
  76% {
    transform: translateY(-60%);
  }
  77% {
    transform: translateY(-80%);
  }
  98% {
    transform: translateY(-80%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideSolutions {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(0%);
  }
  11% {
    transform: translateY(-11.1%);
  }
  21% {
    transform: translateY(-11.1%);
  }
  22% {
    transform: translateY(-22.2%);
  }
  32% {
    transform: translateY(-22.2%);
  }
  33% {
    transform: translateY(-33.3%);
  }
  43% {
    transform: translateY(-33.3%);
  }
  44% {
    transform: translateY(-44.4%);
  }
  54% {
    transform: translateY(-44.4%);
  }
  55% {
    transform: translateY(-55.5%);
  }
  65% {
    transform: translateY(-55.5%);
  }
  66% {
    transform: translateY(-66.6%);
  }
  76% {
    transform: translateY(-66.6%);
  }
  77% {
    transform: translateY(-77.7%);
  }
  87% {
    transform: translateY(-77.7%);
  }
  88% {
    transform: translateY(-88.8%);
  }
  98% {
    transform: translateY(-88.8%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.approach-solutions-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: var(--wrap);
  margin: 0 auto;
  text-align: start;
}

.approach-solutions-subcontainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.approach-solutions-subcontainer.arrow {
  position: absolute;
  visibility: hidden;
  width: fit-content;
  flex-grow: 0;
}

.approach-solutions-label {
  font-size: 12.8px;
  -webkit-text-size-adjust: 100%;
  font-weight: 100;
}

.approach-solutions-text {
  border-radius: 4px;
  border: 1px solid var(--color-white);
  /*background-color: var(--color-grey);
  color: var(--color-text);*/
  padding: 0 8px;
  width: 100%;
  margin-right: auto;
  height: 32px;
  overflow: hidden;
}

.approach-solutions-challenges {
  animation: slideChallenges 20s linear infinite;
}

.approach-solutions-solutions {
  animation: slideSolutions 20s linear infinite;
}

.approach-solutions-solution {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  padding: 4px 0;
}

.approach-solutions-arrow {
  padding: 4px 0;
  width: fit-content;
  margin: 0 auto;
}

/*#endregion*/

/* PREVIOUS WORK STYLES */
/*#region*/

.previous-work-container {
  margin-top: -32px;
  padding: 32px 2vw 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-white);
  border-radius: 32px;
  background-color: var(--color-white);
}

.previous-work {
  height: fit-content;
  padding: 0 24px;
  margin: 0 auto;
  width: 100vw;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.previous-work-header {
  position: sticky;
  top: 0;
  background-color: var(--color-accent);
  padding: calc(3 * var(--margin-flexible)) 0 0;
  z-index: 4;
}

.previous-work-headline-background {
  border-radius: 32px 32px 0 0;
  background-color: var(--color-white);
  padding: 0 2vw 16px;
}

.previous-work-headline {
  font-size: var(--font-size-heading);
  font-weight: 600;
  scroll-margin-top: 96px;
  margin: 0 auto;
  max-width: 980px;
  padding: 32px 24px 0;
}

.previous-work-background {
  background-color: var(--color-white);
  padding: 0 2vw 64px;
  margin: -32px 0 -48px;
  border-top: none;
}

.previous-work-background.shadow {
  margin-top: 0;
  border-radius: 32px 32px 0 0;
  border-top: 1px solid var(--color-grey);
  box-shadow: rgba(100, 100, 111, 0.15) 0px -9px 20px 0px;
}

.previous-work-background.shadow.last {
  margin-bottom: 0;
  padding-bottom: 0;
}

.previous-work-background-column {
  margin: 0 auto;
  padding: 32px 24px;
  max-width: 980px;
  display: flex;
  flex-direction: column;
}

.previous-work-background-column.last {
  padding-bottom: 0px;
}

.previous-work-example {
  display: flex;
  flex-direction: row;
  flex-wrap: var(--wrap);
  gap: 0 var(--margin-flexible);
  margin: var(--margin-flexible) 0;
}

.previous-work-example.reverse {
  flex-wrap: var(--wrap-reverse);
}

.previous-work-example-pic::before {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 128px;
  content: "";
  z-index: 2;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    var(--color-white)
  );
}

.previous-work-example-pic {
  position: relative;
  margin: auto 0 -8px;
  padding: 0;
  border-radius: 16px 16px 0 0;
  /*border: 1px solid var(--color-grey);*/
  /*max-width: 600px;*/
  /*border-radius: 16px;
  outline: 1px solid var(--color-grey);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 6px 18px 0px;*/
}

.previous-work-example-pic img {
  vertical-align: top;
  /*width: 62vw;*/
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.previous-work-example-description {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 3;
}

.previous-work-example-description-title {
  font-size: var(--font-size-subheading);
  font-weight: 600;
}

.previous-work-example-description-bullets {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px 16px;
}

.previous-work-example-description-bullet {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.previous-work-example-description-bullet-icon {
  margin: auto 0;
  height: 1.6rem;
}

.icon {
  height: 100%;
}

.previous-work-example-description-bullet-text {
  margin: auto 0;
}

.previous-work-testimonial {
  display: flex;
  flex-direction: row;
  flex-wrap: var(--wrap-reverse);
  gap: 16px;
  max-width: 650px;
  margin: var(--margin-flexible) auto calc(2 * var(--margin-flexible));
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--color-grey);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 6px 18px 0px;
  z-index: 3;
}

.previous-work-testimonial.last {
  margin-bottom: 0;
}

.previous-work-testimonial-pic {
  display: none;
  margin: auto 0;
}

.previous-work-testimonial-pic img {
  vertical-align: top;
  height: 100px;
  border-radius: 50%;
}

.previous-work-testimonial-pic-small {
  margin: auto 0;
}

.previous-work-testimonial-pic-small img {
  vertical-align: top;
  width: 10vw;
  min-width: 48px;
  border-radius: 50%;
}

.previous-work-testimonial-text {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
}

.previous-work-testimonial-quote {
  font-style: oblique;
}

.previous-work-testimonial-name-container {
  color: var(--color-text-light);
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 auto;
}

.previous-work-testimonial-name {
  margin: auto 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: var(--wrap);
}

.previous-work-end {
  position: relative;
  content: "";
  height: 32px;
  background-color: var(--color-white);
  border-radius: 0 0 32px 32px;
  z-index: 2;
}

/*#endregion*/

/* CONTACT STYLES */
/*#region*/

.contact-container {
  position: relative;
  min-height: calc(100vh + 32px);
  margin-top: -32px;
  padding: 128px 2vw 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: hsla(249, 59%, 73%, 1);
  background-image: radial-gradient(
      at 0% 0%,
      hsla(240, 100%, 94%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 100% 0%, hsla(249, 59%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 0%, 89%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(249, 68%, 51%, 1) 0px, transparent 50%);
  z-index: 1;
}

.contact {
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contact-headline {
  max-width: 650px;
  font-size: var(--font-size-heading);
  font-weight: 600;
  scroll-margin-top: 96px;
  margin-top: 32px;
}

.contact-content {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: var(--wrap);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.contact-form .button {
  width: 100%;
  max-width: 550px;
}

.contact-form .button-font {
  margin-left: auto;
  margin-right: auto;
}

.contact-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  max-width: 420px;
  border-radius: 16px;
  padding: min(100%, 420px) 16px 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 6px 18px 0px;
  margin: auto auto;
}

.contact-card-pic::before {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 128px;
  content: "";
  z-index: 2;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    var(--color-white) 90%
  );
}

.contact-card-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
}

.contact-card-pic img {
  vertical-align: top;
  border-radius: 16px 16px 0 0;
  width: 100%;
  margin: 0;
  object-fit: scale-down;
}

.contact-card-name {
  font-size: var(--font-size-subsubheading);
  font-weight: 600;
}

.contact-card-socials {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 4;
}

.contact-card-social {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.contact-card-social-icon,
.contact-card-social-icon img {
  margin: auto 0;
  height: 1.6rem;
}

.contact-card-social-text {
  margin: auto 0;
}

.contact-footer {
  position: absolute;
  bottom: var(--margin-flexible);
  font-size: 0.8rem;
  color: var(--color-dark-grey);
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.contact-footer-link {
  cursor: pointer;
}

/*#endregion*/

/* IMPRINT STYLES */
/*#region*/

.imprint-container {
  /*margin-top: 96px;*/
  border-radius: 0;
  background-color: var(--color-white);
  padding: 32px 2vw;
}

.imprint {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 24px;
}

/*#endregion*/

/* DATA STYLES */
/*#region*/

.data-container {
  /*margin-top: 96px;*/
  border-radius: 0 0;
  background-color: var(--color-white);
  padding: 32px 2vw;
}

.data {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 24px;
}

/*#endregion*/

/* BUTTON STYLES */
/*#region*/

.button {
  padding: 12px 24px;
  border-radius: 12px;
  /*outline: 1px solid var(--color-text);
  outline-offset: -1px;*/
  border-style: none;
  border-width: 1px;
  border-color: var(--color-grey);
  background-color: var(--color-text);
  box-shadow: rgba(100, 100, 111, 0) 0px 9px 20px 0px;
  position: relative;
  transition: box-shadow var(--transition-duration);
}

.button.accent {
  background-color: var(--color-accent);
  outline: none;
  box-shadow: rgba(3, 3, 203, 0) 0px 9px 20px 0px;
}

.button.disabled {
  background-color: var(--color-dark-grey);
  outline-color: var(--color-dark-grey);
}

.button.small {
  padding: 6px;
}

.button:hover {
  border-style: none !important;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 9px 20px 0px;
  cursor: pointer;
  transition: box-shadow var(--transition-duration);
}

.button.accent:hover {
  box-shadow: var(--color-accent-light) 0px 9px 15px 0px;
}

.button.disabled:hover {
  box-shadow: var(--color-accent-light) 0px 0px 0px 0px !important;
  cursor: not-allowed;
}

.button:active {
  box-shadow: rgba(100, 100, 111, 0) 0px 9px 15px 0px !important;
  transition: box-shadow var(--transition-duration-short);
}

.button.accent:active {
  box-shadow: rgba(100, 100, 111, 0) 0px 9px 20px 0px !important;
  transition: box-shadow var(--transition-duration-short);
}

.button-content {
  position: relative;
  display: flex;
  flex-direction: row;
}

.button-font {
  font-size: var(--font-size-normal);
  font-weight: 400;
  color: var(--color-white);
}

.button.accent .button-font {
  font-weight: 600;
  color: var(--color-white);
}

/*#endregion*/

/* MENU STYLES */
/*#region*/

.menu-container {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--transition-duration);
}

.menu-container.open {
  position: fixed;
  visibility: visible;
  opacity: 100%;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  background-color: var(--color-white);
  padding: 128px 2vw 0;
  z-index: 10;
}

.menu {
  margin: auto 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.menu-item {
  cursor: pointer;
}

/*#endregion*/

/*INPUT STYLES*/
/*#region*/

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: var(--color-bg);
  border-radius: inherit;
  border: 1px solid var(--color-grey);
  /*padding: 8px;*/
  outline: none;
  width: 100%;
  max-width: 550px;
  text-align: inherit;
}

input:focus {
  border-color: var(--color-accent);
  outline: none;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--color-bg);
  /* Not removed via appearance */
  margin: auto 0;

  font: inherit;
  color: var(--color-text);
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid var(--color-grey);
  border-radius: 4px;
  transform: translateY(-0em);
  margin: 4.5px 0;
  padding: 8px;

  display: grid;
  place-content: center;
  z-index: 3;
}

input[type="checkbox"]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 80ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-accent);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

label {
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  color: var(--color-text-light);
  line-height: 0.8rem;
  margin: 0;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  max-width: 550px;
  font-size: 1rem;
  line-height: 1.6rem;
  padding: 12px;
  margin-top: -26px;
  padding-top: 26px;
  background-color: var(--color-bg);
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  cursor: pointer;
}

select:focus {
  outline: none;
}

textarea {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  max-width: 550px;
  font-size: 1rem;
  line-height: 1.6rem;
  background-color: var(--color-bg);
  border: 1px solid var(--color-grey);
  border-radius: inherit;
  cursor: pointer;
  resize: none;
  min-height: 8rem;
}

textarea:focus {
  border-color: var(--color-accent);
  outline: none;
}

.input {
  width: 100%;
  min-width: fit-content;
  font-size: 1rem;
  line-height: 1.6rem;
  /*padding: 8px;*/
  border-radius: 12px;
}

.input > input,
textarea {
  padding: 12px;
  margin-top: -26px;
  padding-top: 26px;
}

.input.semismall {
  /*padding: 6px;*/
  font-size: 1rem;
  line-height: 1rem;
  min-width: 2.8rem;
  width: 16ch;
  text-align: left;
}

.input.semismall > input {
  padding: 4px;
}

.input.small {
  /*padding: 6px;*/
  font-size: 1rem;
  line-height: 1rem;
  min-width: 2.8rem;
  width: 4ch;
  text-align: inherit;
}

.input.small > input {
  padding: 4px;
}

.input-checkbox-container {
  margin-top: 8px;
  width: fit-content;
  min-width: 280px;
}

.input-checkbox-container input {
  zoom: 1.5;
}

.input-checkbox {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6rem;
  background-color: var(--color-bg);
  border-radius: 8px;
  border: 1px none var(--color-grey);
  padding: 0px 8px 0px 0px;
  outline: none;
  min-width: 190px;
  max-width: 280px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.input-checkbox-description {
  margin: auto 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  z-index: 3;
}

.input-label {
  position: relative;
  top: -0rem;
  left: 11px;
  padding: 8px 4px 4px 4px;
  margin: -0rem 0 0 -2px;
  width: calc(100% - 24px);
  max-width: 426px;
  line-height: 0.8rem;
  /*background-color: var(--color-bg);*/
  border-radius: 2px;
  z-index: 3;
}

.input-length-error {
  margin-top: var(--margin-small);
  font-size: 0.8rem;
  color: var(--color-warning);
}

.select {
  position: relative;
  max-width: 550px;
}

.react-select__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.react-select__menu {
  z-index: 4 !important;
}

/*#endregion*/

@media screen and (min-width: 481px) {
  body {
    -webkit-text-size-adjust: 100%;
  }

  .approach-graph-container {
    min-height: 340px;
  }

  .previous-work-example-description-bullets {
    grid-template-columns: auto auto;
  }

  .about-me-content-card {
    width: fit-content;
  }
}

@media screen and (min-width: 641px) {
  :root {
    --wrap: nowrap;
    --wrap-reverse: nowrap;
    --font-size-normal: 1rem;
    --font-size-subheading: 1.8rem;
    --font-size-heading: 2.4rem;
    --font-size-title: 3.8rem;

    --margin-flexible: 32px;
    --margin-flexible-small: 8px;
  }

  .app-header-menu {
    position: absolute;
    visibility: hidden;
  }

  .app-header-sections,
  .app-header-button,
  .welcome-banner-text {
    position: inherit;
    visibility: visible;
  }

  .welcome-banner-text.mobile {
    visibility: hidden;
    position: absolute;
  }

  .approach-container {
    padding-bottom: 0;
  }

  .approach-graphic {
    min-height: 400px;
  }

  .approach-graph-container {
    min-height: 340px;
  }

  .approach-solutions-subcontainer.arrow {
    position: relative;
    visibility: visible;
  }

  .previous-work-background.shadow {
    margin-top: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .previous-work-example-pic::before {
    content: none;
  }

  .previous-work-example-pic {
    position: relative;
    margin: auto 0;
    padding: 0;
    border-radius: 0;
  }

  .previous-work-testimonial {
    margin: 0 auto 0;
  }

  .previous-work-testimonial.last {
    margin: 0 auto 16px;
  }

  .previous-work-testimonial-text {
    gap: 8px;
  }

  .previous-work-testimonial-pic {
    display: block;
  }

  .previous-work-testimonial-pic-small {
    display: none;
  }

  .previous-work-example-description-bullets {
    grid-template-columns: auto;
  }

  .contact-form {
    width: 60%;
  }

  .contact-card {
    width: 280px;
    padding: 280px 16px 16px;
    margin: auto 0 auto auto;
  }
}

@media screen and (min-width: 961px) {
  :root {
    --wrap-reverse2: nowrap;
  }

  .app-header-name-slogan {
    position: inherit;
    visibility: visible;
  }

  .about-me-content {
    display: flex;
  }

  .about-me-content-card {
    min-width: 320px;
  }

  .about-me-content-pic img {
    margin-bottom: 0;
  }

  .approach-graph-container {
    min-height: 340px;
  }

  .previous-work-example-pic img {
    width: 62vw;
    max-width: 590px;
  }
}
